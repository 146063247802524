<template>
  <vue-final-modal
    class="modal-geo-popup"
    :clickToClose="false"
    :focus-trap="false"
    :overlayTransition="{ mode: 'in-out', duration: 200 }"
    :contentTransition="{ mode: 'in-out', duration: 200 }"
  >
    <div class="modal-geo-popup__container">
      <div class="modal-geo-popup__top">
        <button class="modal-geo-popup__close-btn" @click="closeModal(ModalName.GEO_POPUP)">
          <atomic-icon id="close" />
        </button>
      </div>
      <div class="modal-geo-popup__bottom">
        <div class="modal-geo-popup__title">
          {{ getContent(popupsData, defaultLocalePopupsData, 'geoPopup.title') }}
        </div>
        <div class="modal-geo-popup__description" v-html="description" />
        <button-base type="primary" class="modal-geo-popup__got-it" @click="closeModal(ModalName.GEO_POPUP)">{{
          getContent(layoutData, defaultLocaleLayoutData, 'buttons.gotIt')
        }}</button-base>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';

  import { ModalName } from '@skeleton/consts/modals';

  const modalStore = useModalStore();
  const { popupsData, defaultLocalePopupsData, layoutData, defaultLocaleLayoutData } = storeToRefs(useGlobalStore());
  const { closeModal } = modalStore;

  const { getContent, localizePath } = useProjectMethods();

  const description = computed(() => {
    let desc = getContent(popupsData.value, defaultLocalePopupsData.value, 'geoPopup.description');

    if (desc) {
      desc = desc.replace('{url}', localizePath('/support'));
    }

    return desc;
  });
</script>

<style src="~/assets/styles/components/modal/geo-popup.scss" lang="scss" />
